import React from 'react';
import { useWatch } from 'react-hook-form';

import { Button } from 'ui-components/v2/Button';
import { getSubtotal } from 'utils/getSubtotal';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';

import { HookFormProps, PDPProps } from 'pages/pdp/interface';
import { StyledNumberFormat, StyledDiscountNumberFormat } from './styled';

export const ATC = ({
  control,
  product,
  loading,
  onSubmit,
  handleSubmit,
}: Pick<PDPProps, 'product'> & Omit<HookFormProps, 'setValue'>) => {
  const addons = useWatch({ control, name: FIELD_NAMES.addons });
  const noStock = useWatch({ control, name: FIELD_NAMES.noStock });
  const subtotal = getSubtotal(
    addons,
    product.price,
    product.sales_price,
  ).toFixed(2);
  const isProductOnSale =
    product.sales_price && product.sales_price !== product.price;

  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      className="tracking-add-to-cart"
      disabled={loading || !product.purchasable || noStock}
      onClick={handleSubmit(onSubmit)}
    >
      {noStock || !product.purchasable ? (
        'Sold Out!'
      ) : (
        <>
          Add To Cart -{' '}
          {isProductOnSale ? (
            <>
              <StyledNumberFormat
                value={getSubtotal(
                  addons,
                  product.price,
                  product.price,
                ).toFixed(2)}
                displayType="text"
                thousandSeparator
                prefix="$"
                isdiscounted="true"
              />
              <StyledDiscountNumberFormat
                value={subtotal}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </>
          ) : (
            <StyledNumberFormat
              value={subtotal}
              displayType="text"
              thousandSeparator
              prefix="$"
              isdiscounted=""
            />
          )}
        </>
      )}
    </Button>
  );
};
