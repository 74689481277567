import { styled } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { between, down } from 'utils/breakpointHelpers';

export const RootStyled = styled(Box)({
  position: 'relative',
  marginRight: 20,
  [down('sm')]: {
    marginRight: 24,
  },
});

export const CardHeaderStyled = styled(Typography)({
  fontSize: 18,
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  marginBlock: 6,
  [between('md', 'sm')]: {
    marginTop: 12,
    marginBottom: 16,
  },
});

export const CardContentStyled = styled(CardContent)({
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 15,
  [between('md', 'sm')]: {
    paddingBlock: 18,
    marginBottom: 20,
  },
});

export const CardActionsStyled = styled(CardActions)({
  padding: 0,
});

export const LinkStyled = styled(Link)({
  cursor: 'pointer',
});
