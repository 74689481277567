import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import InfoPopover from 'components/InfoPopover/InfoPopover';
import { pushMetricsEvent } from 'hooks/useDataLayer';
import { isFeatureEnabled } from 'utils/featureFlags';
import AddonItem from './AddonItem';
import {
  Addon,
  CartAddonsProps,
  AddonSliderArrowProps,
  Action,
} from '../interface';
import {
  RootStyledProgress,
  ArrowButtonStyled,
  HeaderStyled,
  RootCartStyled,
} from '../styled';
import { useAddonsUtils } from './useAddonsUtils';
import { useAddons } from '../useAddons';
import { addonsUpdater } from '../utils';

const ArrowNextButton = ({ onClick }: AddonSliderArrowProps) => (
  <ArrowButtonStyled
    className="tracking-view-more-add-ons-arrow"
    onClick={onClick}
  >
    <ArrowForwardIosIcon fontSize="small" />
  </ArrowButtonStyled>
);

const Addons = ({
  date,
  token,
  zipCode,
  quantity,
  productId,
  cartAddons,
  cartLineId,
}: CartAddonsProps) => {
  const [selectedAddons, setSelectedAddons] = useState<Addon[]>(cartAddons);
  const [showLoader, setShow] = useState(false);
  const { addons, getLoading, postLoading, handleSubmit } = useAddons({
    date,
    token,
    productId,
    zipCode,
    quantity,
    cartLineId,
  });
  const config = useAddonsUtils(addons.length);

  const handleAddonSelect = (addon: Addon, action: Action) => {
    const newAddons = addonsUpdater({
      addon,
      action,
      selectedAddons,
    });

    pushMetricsEvent('ga4', {
      event: action === 'increase' ? 'add_to_cart' : 'remove_from_cart',
      ecommerce: {
        currency: 'USD',
        value: Number(addon.price),
        items: [
          {
            item_id: addon.product_id.toString(),
            item_name: addon.name,
            item_category: 'add_on',
            price: Number(addon.price),
            quantity: 1,
          },
        ],
      },
    });

    setSelectedAddons(newAddons);
    handleSubmit(newAddons);
  };

  useEffect(() => {
    if (postLoading) setShow(true);
  }, [postLoading]);

  if (showLoader || getLoading) {
    return (
      <RootStyledProgress
        className={
          isFeatureEnabled('newCart')
            ? 'mb-6 ml-3 rounded-md bg-gray-100/70'
            : ''
        }
      >
        <CircularProgress />
      </RootStyledProgress>
    );
  }

  if (!addons.length) {
    return null;
  }

  return (
    <RootCartStyled
      className={
        isFeatureEnabled('newCart')
          ? 'mb-6 ml-3 rounded-md bg-gray-100/70 px-4 py-[10px]'
          : ''
      }
    >
      <div style={{ display: 'flex' }}>
        <HeaderStyled variant="h2">
          Pairs <b>well</b> with...
        </HeaderStyled>
        <InfoPopover text="To protect your arrangement during transit, add-on quantities are limited to 3 per product. Maximum 1 vase." />
      </div>
      <Slider
        speed={500}
        slidesToScroll={1}
        nextArrow={<ArrowNextButton />}
        {...config}
      >
        {addons.map((addon) => {
          const selectedAddon = selectedAddons.filter(
            (a) => a.product_id === addon.product_id,
          );
          const isAddonSelected = Boolean(selectedAddon.length);

          return (
            <AddonItem
              key={addon.product_id}
              addon={addon}
              quantity={isAddonSelected ? selectedAddon[0].quantity : 0}
              selected={isAddonSelected}
              onSelect={handleAddonSelect}
            />
          );
        })}
      </Slider>
    </RootCartStyled>
  );
};

export default Addons;
