import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';

import { ATC } from 'components/ATC';
import { down, up } from 'utils/breakpointHelpers';
import Image from 'ui-components/Image';
import { HookFormProps, PDPProps } from '../interface';
import {
  ContainerStyled,
  ContainerPosition,
  ContainerPositionLg,
  TitleStyled,
  ImageContainerStyled,
  ATCContainerStyled,
} from './styled';

const PurchaseInfo = ({
  product,
  ...formProps
}: Pick<PDPProps, 'product'> & Omit<HookFormProps, 'setValue'>) => (
  <ContainerStyled>
    <ImageContainerStyled>
      <Image
        useWebP
        isSquare
        width="100%"
        height="100%"
        src={product.main_image.cloudinary_photo_id}
        alt={`image of ${product.main_image.filename}`}
      />
    </ImageContainerStyled>
    <ATCContainerStyled>
      <Grid item>
        <TitleStyled variant="h2">{product.name}</TitleStyled>
      </Grid>
      <Grid style={{ minWidth: 260 }} item>
        <ATC product={product} {...formProps} />
      </Grid>
    </ATCContainerStyled>
  </ContainerStyled>
);

const StickyATC = ({
  product,
  ...formProps
}: Pick<PDPProps, 'product'> & Omit<HookFormProps, 'setValue'>) => {
  const [displayStyle, setDisplay] = useState({ top: -104, bottom: -203 });
  const matches = useMediaQuery(up('md'));
  const matchesMobileLandscape = useMediaQuery(
    `${down('md')} and (orientation: landscape)`,
  );

  useEffect(() => {
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setDisplay({ top: 0, bottom: 0 });
      } else {
        setDisplay({ top: -104, bottom: -203 });
      }
    };
    window.addEventListener('scroll', scrollFunction);
    return () => window.removeEventListener('scroll', scrollFunction);
  }, []);

  return matches || matchesMobileLandscape ? (
    <ContainerPositionLg aria-hidden style={{ top: displayStyle.top }}>
      <PurchaseInfo product={product} {...formProps} />
    </ContainerPositionLg>
  ) : (
    <ContainerPosition aria-hidden style={{ bottom: displayStyle.bottom }}>
      <PurchaseInfo product={product} {...formProps} />
    </ContainerPosition>
  );
};

export default StickyATC;
