import { useEffect, useMemo } from 'react';
import useFetch from 'use-http';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';

import { ApiRoutes } from 'constants/api';
import { ShopPageFilters } from '../interface';
import { Filter, GetShopPageFiltersResponseData } from './interface';
import {
  ProductsFormFieldNames as FIELD_NAMES,
  PRODUCTS_FORM_DEFAULT_VALUES,
} from './constants';

interface UseFiltersProps
  extends Omit<ShopPageFilters, 'min_price' | 'max_price'> {
  priceRange: number[];
}

const getAppliedFiltersNumber = (form: any, defaultRange: Number[]) => {
  let counter = 0;
  if (
    form[FIELD_NAMES.priceRange][0] !== defaultRange[0] ||
    form[FIELD_NAMES.priceRange][1] !== defaultRange[1]
  ) {
    counter += 1;
  }

  Object.keys(form).forEach((key) => {
    if (Array.isArray(form[key])) {
      const result = form[key].reduce((sum: number, current: any) => {
        if (current?.checked === true) {
          return sum + 1;
        }
        return sum;
      }, 0);
      counter += result;
    }
  });

  return counter;
};

/* Shop Page Filters Form logic */
export const useFilters = ({
  priceRange,
  sort_by: sortBy,
  zip_code: zipCode,
  shop_page: shopPage,
  delivery_date: filterDate,
  discountable_only: discountableOnly,
}: UseFiltersProps) => {
  const { register, control, watch, setValue, setFocus } = useForm<FieldValues>(
    {
      defaultValues: {
        ...PRODUCTS_FORM_DEFAULT_VALUES,
        [FIELD_NAMES.sortBy]: sortBy,
        [FIELD_NAMES.marketingCategory]: shopPage.slug,
        [FIELD_NAMES.zipCode]: zipCode,
        [FIELD_NAMES.deliveryDate]: filterDate,
        [FIELD_NAMES.priceRange]: priceRange,
        [FIELD_NAMES.discountable_only]: discountableOnly,
      },
    },
  );

  const form = watch();
  const appliedFiltersNumber = useMemo(
    () => getAppliedFiltersNumber(form, priceRange),
    [form],
  );

  const { get, data, loading, error } =
    useFetch<GetShopPageFiltersResponseData>(ApiRoutes.ShopPageFilters);

  /** Why FieldArray? Performance, see react-hook-form docs for details. */
  const { append: productTypeAppend, fields: productTypeFields } =
    useFieldArray({
      control,
      name: FIELD_NAMES.productType,
    });

  const { append: occasionAppend, fields: occasionFields } = useFieldArray({
    control,
    name: FIELD_NAMES.occasion,
  });

  const { append: collectionAppend, fields: collectionFields } = useFieldArray({
    control,
    name: FIELD_NAMES.collection,
  });

  const { append: flowerTypeAppend, fields: flowerTypeFields } = useFieldArray({
    control,
    name: FIELD_NAMES.flowerType,
  });

  useEffect(() => {
    if (!data && !loading) {
      get(`?shop_page_slug=${encodeURIComponent(shopPage.slug)}`);
    }
    if (data && !error && !productTypeFields.length) {
      const byName = (a: Filter, b: Filter) => a.name.localeCompare(b.name);
      const byId = (a: Filter, b: Filter) => a.id - b.id;
      const mapIdToFilterId = (filter: Filter) => ({
        ...filter,
        filterId: filter.id,
      });

      productTypeAppend(
        data.product_type?.sort(byId)?.map(mapIdToFilterId) || [],
      );
      flowerTypeAppend(
        data.flower_type?.sort(byName)?.map(mapIdToFilterId) || [],
      );
      occasionAppend(data.occasion?.sort(byName)?.map(mapIdToFilterId) || []);
      collectionAppend(
        data.collection?.sort(byName)?.map(mapIdToFilterId) || [],
      );
    }
  }, [data]);

  /** Reset filters */
  const resetHandler = () => {
    if (appliedFiltersNumber === 0) {
      setValue(FIELD_NAMES.deliveryDate, null);
    }

    setValue(FIELD_NAMES.discountable_only, null);
    setValue(FIELD_NAMES.priceRange, priceRange);
    setValue(FIELD_NAMES.productType, productTypeFields);
    setValue(FIELD_NAMES.flowerType, flowerTypeFields);
    setValue(FIELD_NAMES.collection, collectionFields);
    setValue(FIELD_NAMES.occasion, occasionFields);
  };

  return {
    register,
    control,
    setValue,
    setFocus,
    watch,
    reset: resetHandler,
    appliedFiltersNumber,
  };
};
