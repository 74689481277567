import { styled } from '@material-ui/core';
import NumberFormat from 'react-number-format';

export const StyledNumberFormat = styled(NumberFormat)(
  (props: { isdiscounted: string }) => ({
    marginLeft: 4,
    textDecoration: props.isdiscounted ? 'line-through' : 'none',
  }),
);

export const StyledDiscountNumberFormat = styled(NumberFormat)(() => ({
  color: '#EF868F',
  marginLeft: 8,
}));
