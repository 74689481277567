import qs from 'qs';
import dayjs from 'dayjs';
import { dateFormatter } from 'utils/dateHelpers';
import { UNAVAILABLE_DAY_DATE_FORMAT } from 'constants/time';

import { Fields } from '../interface';
import {
  ProductsFormFieldNames as FIELD_NAMES,
  SORTBY_VALUES,
} from './constants';

function filterNonNull(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v));
}

const importIds = (form: any, field: FIELD_NAMES) =>
  form[field]
    ?.filter((checkbox: Fields[0]) => checkbox.checked)
    .map((checkbox: Fields[0]) => checkbox.filterId) || [];

// Turns shop page filters form values into a query string for API
export function getFiltersQs(form: any) {
  const productTypeIds = importIds(form, FIELD_NAMES.productType);
  const flowerTypeIds = importIds(form, FIELD_NAMES.flowerType);
  const occasionIds = importIds(form, FIELD_NAMES.occasion);
  const collectionIds = importIds(form, FIELD_NAMES.collection);

  let queryString: string = '?';

  const deliveryDateObj = form[FIELD_NAMES.deliveryDate]
    ? dayjs(dateFormatter(form[FIELD_NAMES.deliveryDate]))
    : null;

  const deliveryDate = deliveryDateObj?.isValid()
    ? deliveryDateObj.format(UNAVAILABLE_DAY_DATE_FORMAT)
    : null;

  const discountableOnly = form[FIELD_NAMES.discountable_only] || null;
  const zipCode = form[FIELD_NAMES.zipCode] || '';
  const marketingCategory = form[FIELD_NAMES.marketingCategory] || '';
  const minPrice = form[FIELD_NAMES.priceRange][0];
  const maxPrice = form[FIELD_NAMES.priceRange][1];

  const qsObj = filterNonNull({
    [FIELD_NAMES.deliveryDate]: deliveryDate,
    [FIELD_NAMES.zipCode]: zipCode,
    [FIELD_NAMES.marketingCategory]: marketingCategory,
    [FIELD_NAMES.discountable_only]: discountableOnly,
    [FIELD_NAMES.sortBy]: form[FIELD_NAMES.sortBy] ?? SORTBY_VALUES.recommended,
    [FIELD_NAMES.productType]: productTypeIds,
    [FIELD_NAMES.flowerType]: flowerTypeIds,
    [FIELD_NAMES.occasion]: occasionIds,
    [FIELD_NAMES.collection]: collectionIds,
    min_price: minPrice,
    max_price: maxPrice,
  });

  queryString += `${qs.stringify(qsObj, { arrayFormat: 'comma' })}`;

  return queryString;
}
