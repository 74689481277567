import React from 'react';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';

import Image from 'ui-components/Image';
import { Addon } from 'components/Addons/interface';
import { RootStyled, CardHeaderStyled } from './styled';

const AddonItem = ({ name, price, cloudinary_id: image, quantity }: Addon) => (
  <RootStyled>
    <Image src={image} alt={name} width="100%" isSquare />
    <CardHeaderStyled variant="body1" noWrap>
      {name}
    </CardHeaderStyled>
    <Typography variant="body1">
      Price:{' '}
      <NumberFormat
        value={Number(price).toFixed(2)}
        displayType="text"
        thousandSeparator
        prefix="$"
      />
    </Typography>
    <Typography variant="body1">Qty: {quantity}</Typography>
  </RootStyled>
);

export default AddonItem;
