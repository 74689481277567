import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { Checkbox } from 'ui-components/v2/Checkbox';
import { FormControlLabel } from 'ui-components/v2/FormControlLabel';
import { ProductsFormFieldNames as FIELD_NAMES } from 'pages/shop/hooks/constants';

import { Slider } from 'ui-components/v2/Slider';
import { PriceSelectProps } from './interface';
import {
  Title,
  TextFieldsContainer,
  HorizontalDivider,
  PriceTextField,
} from './styled';

const PRICE_LIMITS = [100, 200, 300] as const;
type PriceLimitType = (typeof PRICE_LIMITS)[number];
type TextChangeType = 'from' | 'under';

export function PriceSelect({ control, priceRange }: PriceSelectProps) {
  const minPrice = priceRange[0];
  const maxPrice = priceRange[1];
  const {
    field: { onChange, value: priceRangeValues },
  } = useController({
    name: FIELD_NAMES.priceRange,
    control,
  });

  const [reset, setReset] = useState<boolean>(false);
  const [from, setFrom] = useState<number | null>(minPrice);
  const [under, setUnder] = useState<number>(maxPrice);

  const handlePriceCheckBoxClick = (
    value: PriceLimitType,
    isChecked: boolean,
  ) => {
    if (from !== 1) setFrom(1);
    setReset(false);

    if (isChecked) {
      setUnder(value);
    } else {
      setUnder(maxPrice);
    }
  };

  const onTextFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: TextChangeType,
  ) => {
    const { value } = e.target;
    if (!value && type === 'from') {
      setFrom(minPrice);
      return;
    }
    if (minPrice > +value || +value > maxPrice) return;

    const cantSetFrom = (under && +value >= under) || type === 'under';
    const cantSetUnder = type === 'from';

    if (!reset) setReset(true);
    if (!cantSetFrom) setFrom(+value);
    if (!cantSetUnder) setUnder(+value);
  };

  const onSliderChange = (_: any, value: number | number[]) => {
    const [fromValue, underValue] = value as number[];
    if (fromValue > underValue || underValue < fromValue) return;

    const cantSetFrom = under && fromValue >= under;
    const cantSetUnder = from && underValue <= from;

    if (!reset) setReset(true);
    if (!cantSetFrom) setFrom(fromValue);
    if (!cantSetUnder) setUnder(underValue);
  };

  // const debouncedCb = useCallback(
  //   debounce((priceRange: number[]) => onChange(priceRange), 300), [],
  // )

  useEffect(() => {
    onChange([from, under]);
  }, [from, under]);

  useEffect(() => {
    if (priceRangeValues[0] === minPrice) {
      setFrom(priceRangeValues[0]);
    }
    if (priceRangeValues[1] === maxPrice) {
      setUnder(priceRangeValues[1]);
    }
  }, [priceRangeValues[0], priceRangeValues[1]]);

  return (
    <>
      <Title id="price-filters-label" variant="body1">
        <strong>Price</strong>
      </Title>
      <TextFieldsContainer>
        <PriceTextField
          label="From"
          value={from}
          aria-label="from"
          type="number"
          variant="outlined"
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => onTextFieldChange(e, 'from')}
        />
        <HorizontalDivider>—</HorizontalDivider>
        <PriceTextField
          label="Under"
          value={!!under && under}
          aria-label="under"
          type="number"
          variant="outlined"
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => onTextFieldChange(e, 'under')}
        />
      </TextFieldsContainer>
      <Slider
        min={minPrice}
        max={maxPrice}
        onChange={onSliderChange}
        value={[from || 1, under || maxPrice]}
      />
      {PRICE_LIMITS.map((price) => (
        <FormControlLabel
          label={`Under $${price}`}
          className="tracking-price-filter-under-x"
          control={<Checkbox checked={under === price && !reset} />}
          onChange={(_, checked) => handlePriceCheckBoxClick(price, checked)}
        />
      ))}
    </>
  );
}
