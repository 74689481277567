import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';
import { HookFormProps } from 'pages/pdp/interface';

import InfoPopover from 'components/InfoPopover/InfoPopover';
import AddonItem from './AddonItem';
import {
  Action,
  Addon,
  AddonSliderArrowProps,
  PDPAddonsProps,
} from '../interface';
import {
  RootStyledProgress,
  ArrowNextButtonStyled,
  RootStyled,
  HeaderStyled,
} from '../styled';
import { useAddonsUtils } from './useAddonsUtils';
import { useAddons } from '../useAddons';
import { addonsUpdater } from '../utils';

const ArrowNextButton = ({ onClick }: AddonSliderArrowProps) => (
  <ArrowNextButtonStyled
    className="tracking-view-more-add-ons-arrow"
    onClick={onClick}
  >
    <ArrowForwardIosIcon fontSize="small" />
  </ArrowNextButtonStyled>
);

const Addons = ({
  date,
  productId,
  zipCode,
  setValue,
  quantity,
  subscription,
}: PDPAddonsProps & Pick<HookFormProps, 'setValue'>) => {
  const [selectedAddons, setSelectedAddons] = useState<Addon[]>([]);
  const { addons, getLoading } = useAddons({
    date,
    productId,
    zipCode,
    quantity,
  });
  const config = useAddonsUtils(addons.length);

  useEffect(() => {
    if (
      !addons.some(
        (addon) => addon.product_id === selectedAddons[0]?.product_id,
      )
    ) {
      setSelectedAddons([]);
      setValue(FIELD_NAMES.addons, []);
    }

    if (addons.length > 3) {
      setValue(FIELD_NAMES.showCart, false);
    }
  }, [addons]);

  useEffect(() => {
    if (subscription) {
      setSelectedAddons([]);
      setValue(FIELD_NAMES.addons, []);
    }
  }, [subscription]);

  const handleAddonSelect = (addon: Addon, action: Action) => {
    const newAddons = addonsUpdater({
      addon,
      action,
      selectedAddons,
    });

    setSelectedAddons(newAddons);
    setValue(FIELD_NAMES.addons, newAddons);
  };

  if (getLoading) {
    return (
      <RootStyledProgress>
        <CircularProgress />
      </RootStyledProgress>
    );
  }

  if (!addons.length) {
    return null;
  }

  return (
    <RootStyled>
      <div style={{ display: 'flex' }}>
        <HeaderStyled variant="h2">
          Pairs <b>well</b> with...
        </HeaderStyled>
        <InfoPopover text="To protect your arrangement during transit, add-on quantities are limited to 3 per product. Maximum 1 vase." />
      </div>
      <Slider
        speed={500}
        slidesToScroll={1}
        nextArrow={<ArrowNextButton />}
        {...config}
      >
        {addons.map((addon) => {
          const selectedAddon = selectedAddons.filter(
            (a) => a.product_id === addon.product_id,
          );
          const isAddonSelected = Boolean(selectedAddon.length);

          return (
            <AddonItem
              key={addon.product_id}
              addon={addon}
              quantity={isAddonSelected ? selectedAddon[0].quantity : 0}
              selected={isAddonSelected}
              onSelect={handleAddonSelect}
            />
          );
        })}
      </Slider>
    </RootStyled>
  );
};

export default Addons;
