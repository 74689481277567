import { Box, styled, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { Button } from 'ui-components/v2/Button';
import { down } from 'utils/breakpointHelpers';

export const ContainerStyled = styled(Box)({
  padding: '20px',
  paddingBottom: '32px',
});

export const ContentStyled = styled(Box)({
  display: 'flex',
  gap: 14,
  flexDirection: 'column',
});

export const ProductLineContainerStyled = styled(Box)({
  display: 'flex',
  gap: 14,
  marginTop: 10,
});

export const ActionButtonStyled = styled(Button)({
  width: 'auto',
  minWidth: '82px',
  [down('sm')]: {
    width: 'auto',
    minWidth: '78px',
    paddingInline: '20px',
  },
});

export const TitleStyled = styled(Typography)({
  margin: '3px 6px 9px',
  fontSize: '34px',
  lineHeight: '40px',
  [down('md')]: {
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: '20px',
  },
  [down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '9px',
  },
});

export const MediaStyled = styled('div')({
  width: '160px',
  height: '160px',
  [down('sm')]: {
    width: '141px',
    height: '141px',
  },
});

export const InfoContainerStyled = styled(Box)({
  [down('sm')]: {
    maxWidth: '40%',
  },
});

export const NameStyled = styled(Typography)({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '28px',
  [down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
});

export const PriceStyled = styled(Typography)({
  fontSize: '18px',
  lineHeight: '24px',
  [down('sm')]: {
    fontSize: '18px',
    lineHeight: '24px',
  },
});

export const NumberFormatStyled = styled(NumberFormat)(() => ({
  textDecoration: 'line-through',
}));

export const DiscountNumberFormatStyled = styled(NumberFormat)(() => ({
  color: '#EF868F',
  marginLeft: 8,
}));
