import { Addon } from 'components/Addons/interface';

export const getSubtotal = (
  addonArr: Addon[],
  price: string,
  salesPrice: string,
): number => {
  const productPrice =
    Number(salesPrice) > 0 ? Number(salesPrice) : Number(price);

  return (
    addonArr.reduce(
      (sum, addon) => sum + addon.quantity * Number(addon.price),
      0,
    ) + productPrice
  );
};

export const getDiscount = (
  price: string,
  salesPrice: string | undefined | null,
) => (salesPrice ? Number(price) - Number(salesPrice) : undefined);
