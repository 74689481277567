import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ProductsFormFieldNames as FIELD_NAMES } from 'pages/shop/hooks/constants';
import { ResultsContainer } from './styled';
import { ResultsProps } from './interface';
import { EmptyState } from '../EmptyState';
import ProductCard from './ProductCard';
import ProductSkeleton from './ProductCard/productsSkeleton';

const skeletonsNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Results = ({
  products,
  loading,
  hasMore,
  loadMore,
  resWithSoonestDate,
  reset,
  hasLoadedOnce,
  hasAborted,
  control,
}: ResultsProps) => {
  const showLoading = !products.length && loading;
  const [nextCardsLoading, setNextCardsLoading] = useState(false);
  const deliveryDate = useWatch({
    control,
    name: FIELD_NAMES.deliveryDate,
  });

  useEffect(() => {
    if (loading) setNextCardsLoading(true);
  }, [products.length && loading]);

  useEffect(() => {
    setNextCardsLoading(false);
  }, [products.length]);

  const skeletons = skeletonsNumber.map((_i) => (
    <Grid item xs={12} sm={6} md={4} key={_i}>
      <ProductSkeleton resWithSoonestDate={resWithSoonestDate} key={_i} />
    </Grid>
  ));

  return (
    <ResultsContainer>
      {(showLoading || hasAborted) && (
        <Grid container spacing={3}>
          {skeletons}
        </Grid>
      )}
      {products.length === 0 &&
        !showLoading &&
        hasLoadedOnce &&
        !hasAborted && <EmptyState reset={reset} deliveryDate={deliveryDate} />}
      {!showLoading && products.length !== 0 && (
        <InfiniteScroll
          loader={null}
          next={loadMore}
          hasMore={!!hasMore}
          scrollThreshold={0.6}
          dataLength={products.length}
          style={{ overflow: 'visible' }}
        >
          <Grid container spacing={3}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.slug}>
                <ProductCard
                  product={product}
                  deliveryDate={deliveryDate}
                  discountPrice={product.sales_price}
                />
              </Grid>
            ))}
            {nextCardsLoading && skeletons}
          </Grid>
        </InfiniteScroll>
      )}
    </ResultsContainer>
  );
};

export default Results;
