import React, { FC, useCallback, useState } from 'react';

import Image from 'ui-components/Image';
import ProductBadge from 'components/ProductBadge';
import { getDiscount } from 'utils/getSubtotal';
import { pushMetricsEvent } from 'hooks/useDataLayer';

import { getProductDateText } from './helpers/getProductDateText';
import { ProductCardProps } from './interface';
import {
  ProductCardInfo,
  ProductImage,
  StyledNumberFormat,
  StyledDiscountNumberFormat,
  ProductTypographySmall,
  ProductTypographySmall2,
  DetailsButtonStyled,
} from './styled';

const ProductCard: FC<ProductCardProps> = ({
  product,
  discountPrice,
  deliveryDate,
}) => {
  const { name, price, tagline, slug } = product;
  const [mouseIn, setMouseIn] = useState(false);

  const mouseEnterHandler = () => {
    setMouseIn(true);
  };

  const mouseLeaveHandler = () => {
    setMouseIn(false);
  };

  const pushSelectItemEvent = useCallback(() => {
    pushMetricsEvent('ga4', {
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: product.id.toString(),
            item_name: product.name.trim(),
            discount: getDiscount(product.price, discountPrice),
            item_category: 'main_product',
            price: Number(product.price),
            quantity: 1,
          },
        ],
      },
    });
  }, [product, discountPrice]);

  return (
    <article
      className="w-full"
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <a href={`/${slug}`} onClick={pushSelectItemEvent}>
        <ProductImage>
          <Image
            src={product?.main_image?.cloudinary_photo_id}
            alt={product?.name}
            width="100%"
            useWebP
            isSquare
          />
          {product.promo_label ? (
            <ProductBadge label={product.promo_label} />
          ) : null}
        </ProductImage>
        <ProductCardInfo className="my-3">
          <h3 className="!font-brandon !m-0 overflow-hidden overflow-ellipsis whitespace-nowrap !text-xl !font-medium !leading-6 !text-black">
            {name}
          </h3>
          {tagline ? (
            <ProductTypographySmall variant="subtitle2">
              {tagline}
            </ProductTypographySmall>
          ) : (
            <div className="h-8" />
          )}
          <ProductTypographySmall2 variant="body2">
            {product?.soonest_delivery_date && (
              <i>
                {getProductDateText(
                  product.soonest_delivery_date,
                  deliveryDate,
                )}
              </i>
            )}
          </ProductTypographySmall2>
        </ProductCardInfo>
      </a>
      <DetailsButtonStyled
        href={`/${slug}`}
        onClick={pushSelectItemEvent}
        mousein={mouseIn ? 'true' : ''}
        color="primary"
        variant="contained"
        fullWidth
      >
        View Details -
        {discountPrice && discountPrice !== price ? (
          <>
            <StyledNumberFormat
              value={Number(price).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
              isdiscounted="true"
            />
            <StyledDiscountNumberFormat
              value={Number(discountPrice).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </>
        ) : (
          <StyledNumberFormat
            value={Number(price).toFixed(2)}
            displayType="text"
            thousandSeparator
            prefix="$"
            isdiscounted=""
          />
        )}
      </DetailsButtonStyled>
    </article>
  );
};

export default ProductCard;
